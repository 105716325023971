import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import ErrorCard from "../../../utils/cards/ErrorCard/ErrorCard";
import MessageCard from "../../../utils/cards/MessageCard/MessageCard";
import Spinner from "../../../utils/Spinner";
import I18n from "../../../utils/I18n";

import UserService from "../../../../services/UserService";
import CookieService from "../../../../services/CookieService";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

const Constants = require("../../../constants/Constants");

class Activate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: false,
			error: false,
			loading: false,
		};
	}

	componentDidMount() {
		this.onActivate();
	}

	// make activation request
	onActivate = () => {
		const splitPath = this.props.history.location.pathname.split("/");

		const userId = splitPath[splitPath.length - 2];
		const code = splitPath[splitPath.length - 1];

		if (!userId || !code) return;

		const self = this;
		self.setState({ loading: true });
		UserService.activateAccount(
			userId,
			code,
			async function () {
				self.setState({
					loading: false,
					message: I18n.t("ACTIVATE_ACCOUNT.SUCCESS"),
					error: false,
				});
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	// return to login page
	onBack = () => {
		this.props.history.push(Constants.ROUTES.LOGIN);
	};

	render() {
		if (CookieService.get("token")) {
			return <Redirect to={Constants.ROUTES.USERS} />;
		}

		const loading = this.state.loading;
		return (
			<div className={loading ? "app flex-row align-items-center Loading" : "app flex-row align-items-center"}>
				<Container>
					{Spinner.render(loading)}
					<Row className="justify-content-center">
						<Col md="6" lg="7" xl="8">
							{this.renderActivateCard()}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	renderMessageCard = () => {
		const message = this.state.message;
		return message && MessageCard.render(message);
	};

	renderErrorCard = () => {
		const error = this.state.error;
		return error && ErrorCard.render(error.errorCode, error.errorMessage);
	};

	renderActivateCard = (disabled) => {
		return (
			<Card className="mx-4">
				<CardBody>
					<h1 style={{ textAlign: "center" }}>{I18n.t("ACTIVATE_ACCOUNT.TITLE")}</h1>
					{/* <Row> <Col xs="6">{this.renderBackButton()}</Col> </Row> */}
					<Row className="justify-content-center">{this.renderErrorCard()}</Row>
					<Row className="justify-content-center">{this.renderMessageCard()}</Row>
				</CardBody>
			</Card>
		);
	};

	renderBackButton = () => {
		return (
			<Button color="link" className="px-0" style={{ float: "right" }} onClick={this.onBack}>
				{I18n.t("CHANGE_PASSWORD.BACK")}
			</Button>
		);
	};
}

export default withRouter(Activate);

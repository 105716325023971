const API_URL = window._env_ && window._env_.REACT_APP_API ? window._env_.REACT_APP_API : process.env.REACT_APP_API;

module.exports = {
	API_ROUTES: {
		RESOURCES: {
			GET_RESOURCE: (resId) => API_URL + "/resource/" + resId,
		},
		USER: {
			GET_ALL: API_URL + "/user/all/",
			GET_TOKEN: API_URL + "/user/",
			GET: (userId) => API_URL + "/user/" + userId,
			LOGIN: API_URL + "/user/login",
			SIGNUP: API_URL + "/user/signup/",
			ACTIVATE: (userId, code) => API_URL + "/user/" + userId + "/activate/" + code,
			ACTIVATE_ADMIN: (userId) => API_URL + "/user/" + userId + "/activate/",
			EDIT: (userId) => API_URL + "/user/" + userId,
			EDIT_LANGUAGE: API_URL + "/user/language",
			SEND_PASSWORD_RECOVERY_EMAIL: API_URL + "/user/forgot",
			RECOVER_PASSWORD: (userId, code) => API_URL + "/user/" + userId + "/password/recover/" + code,
			DELETE: (userId) => API_URL + "/user/" + userId,
			EXPORT: API_URL + "/user/export", 
			REFRESH_TOKEN: API_URL + "/user/refreshToken",
			UPDATE: API_URL + "/user/refresh",
			UPDATE_STATUS: API_URL + "/user/status"
		},
		ADMIN: {
			LOGIN: API_URL + "/admin/login",
			EDIT: (userId) => API_URL + "/admin/" + userId,
			DELETE: (userId) => API_URL + "/admin/" + userId,
			EDIT_PASSWORD: API_URL + "/admin/password",
			GET: (userId) => API_URL + "/admin/" + userId,
			GET_ALL: API_URL + "/admin/all/",
			CREATE: API_URL + "/admin",
			SAVE_NOTIF_TOKEN: API_URL + "/admin/notifToken"
		},
		TRANSACTIONS: {
			GET_PENDING: API_URL + "/transactions/pending/",
			GET_FINISHED: API_URL + "/transactions/finished/",
			GET_PENDING_EXPORT: API_URL + "/transactions/pending/export",
			GET_FINISHED_EXPORT: API_URL + "/transactions/finished/export",
			GET: (id) => API_URL + "/transactions/" + id,
		},
		CHAT: {
			GET_ALL: API_URL + "/chat/all/",
			GET: (id) => API_URL + "/chat/" + id,
			MESSAGES: (id) => API_URL + "/chat/message/" + id,
		},
		FUND:{
			GET_ALL: API_URL + "/fund/all",
			GET: (id) => API_URL + "/fund/" + id,
			CREATE: API_URL + "/fund",
			EDIT: (id) => API_URL + "/fund/" + id,
			DELETE: (id) => API_URL + "/fund/" + id,
		},
		BOND:{
			GET_ALL: API_URL + "/bond/all",
			GET: (id) => API_URL + "/bond/" + id,
			CREATE: API_URL + "/bond",
			EDIT: (id) => API_URL + "/bond/" + id,
			DELETE: (id) => API_URL + "/bond/" + id,
		}
	},
	ROUTES: {
		REGISTER: "/signup",
		ACCOUNT_ACTIVATION: "/activateAccount",
		PASSWORD_RECOVER: "/passwordRecovery",
		FORGOT: "/forgot",
		LOGIN: "/",
		USERS: "/clientes",
		ADMINS: "/admins",
		ADMIN: "/admins/admin",
		ADMIN_ID: "/admins/:adminId",
		PROFILE: "/clientes/:clientId",
		TRANSACTION: "/transaction",
		TRANSACTION_ID: "/transaction/:transactionId",
		CONVERSATION:"/conversation",
		CONVERSATION_ID:"/conversation/:conversationId",
		FUNDS_AND_BONDS:"/fundsAndBonds",
		FUND:"/fundsAndBonds/fund",
		BOND:"/fundsAndBonds/bond",
		FUND_ID:"/fundsAndBonds/editFund/:fundId",
		BOND_ID:"/fundsAndBonds/editBond/:bondId",
	},

	PAGINATION: {
		PAGE_SIZES: [5, 10, 15, 20, 25, 50, 100, 300],
	},

	TABLE: {
		PAGE_SIZE: 10,
		MIN_ROWS: 3,
	},

	TYPES: {
		TEXT: "Text",
		PARAGRAPH: "Paragraph",
		PASSWORD: "Password",
		DATE: "Date",
		EMAIL: "Email",
		PHONE: "Phone",
		NUMBER: "Number",
		BOOLEAN: "Boolean",
		CHECKBOX: "Checkbox",
	},

	RESOURCE_TYPES: {
		AVATARS: "profilePics",
	},

	FACEBOOK: {
		APP_ID: "221163942653005",
	},
	GOOGLE: {
		CLIENT_ID: "281943008407-1coltd10g16kj0c2t9utn7f814tv0pff.apps.googleusercontent.com",
		CLIENT_SECRET_KEY: "zl9zKp9OyNQO2ux19yecxZgC",
	},

	DEFAULT_LANGUAGE: "es",
	AVAILABLE_LANGUAGES: ["en", "es"],

	USER_STATES : [
		{label: "Todos", value: ""},
		{label: "Activo", value: "Activo"},
		{label: "Inactivo", value: "Inactivo"},
	],

	USER_STATES_SELECT: [
		{label: "Activo", value: "Activo"},
		{label: "Inactivo", value: "Inactivo"},
	],
	USER_TYPES_SELECTOR: [
		{label: "Cliente", value: "Cliente"},
		{label: "Admin", value: "ADMIN"},
	],

	USER_TYPES: {
		Admin: "ADMIN",
		Client: "Cliente"
	},

	TRANSACTION:{
		STATES:{
			PENDING: 'pending',
			FINISHED: 'finished',
			CANCELED: 'canceled'
		},
		TYPE: {
			SUBSCRIBE_FUND: 'subscribe fund',
			RESCUE_FUND: "rescue fund",
			BUY_BOND: 'buy bond',
			SELL_BOND: 'sell bond',
		},
		STATES_SELECT: [
			{label: "-", value: ""},
			{label: "Completado", value: "finished"},
			{label: "Cancelado", value: "canceled"},
		],
		TYPES_SELECT: [
			{label: "-", value: ""},
			{label: "Suscripción de Fondo", value: "subscribe fund"},
			{label: "Rescate de Fondo", value: "rescue fund"},
			{label: "Compra de Bono", value: "buy bond"},
			{label: "Venta de Bono", value: "sell bond"},
		],
		EDIT:{
			STATE_SELECT: [
				{label: "Pendiente", value: "pending"},
				{label: "Completado", value: "finished"},
				{label: "Cancelado", value: "canceled"},
			]
		}
	},

	BONDS_CURRENCY: [
		{label: "Pesos", value: "Pesos"},
		{label: "Dolares MEP", value: "Dolares MEP"},
		{label: "Dolares CABLE", value: "Dolares Cable"},
	],

	FUNDS_CURRENCY: [
		{label: "Pesos", value: "Pesos"},
		{label: "Dolares", value: "Dolares"},
	],

	SOCIETY: [
		{label: "Argenfunds S.A.", value: "Argenfunds S.A."},
		{label: "QM ASSET MANAGEMENT S.G.F.C.I.S.A.", value: "QM ASSET MANAGEMENT S.G.F.C.I.S.A."},
		{label: "IEB S.A.", value: "IEB S.A."},
		{label: "Galileo Argentina S.A.", value: "Galileo Argentina S.A."},
		{label: "Southern Trust S.G.F.C.I.S.A.", value: "Southern Trust S.G.F.C.I.S.A."},
		{label: "Consutatio Asset Management", value: "Consutatio Asset Management"},
		{label: "Allaria Ledesma Fondos Administrados S.G.F.C.I.S.A.", value: "Allaria Ledesma Fondos Administrados S.G.F.C.I.S.A."},
	]
};

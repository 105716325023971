import React from "react";
import { ClipLoader } from "react-spinners";

const css =
	"z-index: 1; height: 100px; width: 100px; position: absolute; display: flex; align-self: center;";

export default class Spinner {
	// renders a spinning wheel when loading is true
	static render(loading) {
		return (
			<div 
				style={{
					position: 'absolute',
					display: 'flex',
					"justify-content": 'center',
					"align-items": 'center',
					width: "85%",
					height: "85%",
				}
			}>
				<ClipLoader css={css} size={100} loading={loading} />
			</div>
		)
	}
}

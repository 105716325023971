// import CookieService from "./CookieService";
import HttpService from './HttpService'
const Constants = require("../views/constants/Constants");
const WEB = "Web";

export default class AdminService {
	// login
	static login(email, pass, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.ADMIN.LOGIN,
			{
				clientType: WEB,
				email: email,
				password: pass,
			},
			undefined,
			cb,
			errCb
		);
	}

	// get all users
	static getAll(token,pagination, sort, filter, cb, errCb) {
		const path = new URL(Constants.API_ROUTES.ADMIN.GET_ALL);
		Object.keys(pagination).forEach((key) =>
			path.searchParams.append(key, pagination[key])
		);

		const sortParam = sort.reduce(
			(accum, { column, ascending }, i) =>
				accum.concat(`${i === 0 ? "" : ","}${column}:${ascending ? "asc" : "desc"}`),
			""
		);
		path.searchParams.append("sort", sortParam);

		const filterParam = Object.values(filter).reduce(
			(accum, filterElem, i) =>
				accum.concat(`${i === 0 ? "" : ","}${filterElem.column}:${filterElem.value}`),
			""
		);
		path.searchParams.append("filter", filterParam);
		HttpService.httpCall("GET", path, undefined, token, cb, errCb);
	}

	// get user by id
	static get(token, userId, cb, errCb) {
		HttpService.httpCall("GET", Constants.API_ROUTES.ADMIN.GET(userId), undefined, token, cb, errCb);
	}

	// saves notif token
	static saveNotifToken(token, notifToken, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.ADMIN.SAVE_NOTIF_TOKEN,
			{
				notifToken,
			},
			token,
			cb,
			errCb
		);
	}

	// deletes notif token
	static deleteNotifToken(token, cb, errCb) {
		HttpService.httpCall(
			"DELETE",
			Constants.API_ROUTES.ADMIN.SAVE_NOTIF_TOKEN,
			undefined,
			token,
			cb,
			errCb
		);
	}

	// change password
	static editPassword(token, oldPassword, password, cb, errCb) {
		HttpService.httpCall(
			"POST",
			Constants.API_ROUTES.ADMIN.EDIT_PASSWORD,
			{
				oldPassword: oldPassword,
				password: password,
			},
			token,
			cb,
			errCb
		);
	}

	// edit user
	static edit(token, userId, data, cb, errCb) {
		HttpService.httpCall(
			"PUT",
			Constants.API_ROUTES.ADMIN.EDIT(userId),
			data,
			token,
			cb,
			errCb
		);
	}

	// delete admin
	static delete(token, userId, cb, errCb) {
		HttpService.httpCall("DELETE", Constants.API_ROUTES.ADMIN.DELETE(userId), undefined, token, cb, errCb);
	}

	// crete Admin
	static create(token,data,cb,errCb) {
		HttpService.httpCall("POST", Constants.API_ROUTES.ADMIN.CREATE, data, token, cb, errCb);
	}

}

import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import "./MessageCard.scss";

export default class MessageCard {
	static render(body, big) {
		return (
			<Col md={big ? "12" : "8"}>
				<Card className="messageCard text-white bg-success">
					<CardBody>{body}</CardBody>
				</Card>
			</Col>
		);
	}
}

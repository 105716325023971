import CookieService from "./CookieService";
import HttpService from './HttpService'
const Constants = require("../views/constants/Constants");
// const WEB = "Web";

export default class UserService {
	// get all users
	static getAll(token,pagination, sort, filter, cb, errCb) {
		const path = new URL(Constants.API_ROUTES.USER.GET_ALL);
		Object.keys(pagination).forEach((key) =>
			path.searchParams.append(key, pagination[key])
		);

		const sortParam = sort.reduce(
			(accum, { column, ascending }, i) =>
				accum.concat(`${i === 0 ? "" : ","}${column}:${ascending ? "asc" : "desc"}`),
			""
		);
		path.searchParams.append("sort", sortParam);

		const filterParam = Object.values(filter).reduce(
			(accum, filterElem, i) =>
				accum.concat(`${i === 0 ? "" : ","}${filterElem.column}:${filterElem.value}`),
			""
		);
		path.searchParams.append("filter", filterParam);
		HttpService.httpCall("GET", path, undefined, token, cb, errCb);
	}


	// get user by id
	static get(token, userId, cb, errCb) {
		HttpService.httpCall("GET", Constants.API_ROUTES.USER.GET(userId), undefined, token, cb, errCb);
	}

	// edit user
	static edit(token, userId, data, cb, errCb) {
		HttpService.httpCall(
			"PUT",
			Constants.API_ROUTES.USER.EDIT(userId),
			data,
			token,
			cb,
			errCb
		);
	}

	// update users status
	static updateUsersStatus(token, data, cb, errCb) {
		HttpService.httpCall(
			"PUT",
			Constants.API_ROUTES.USER.UPDATE_STATUS,
			data,
			token,
			cb,
			errCb
		);
	}

	// delete user
	static delete(token, userId, cb, errCb) {
		HttpService.httpCall("DELETE", Constants.API_ROUTES.USER.DELETE(userId), undefined, token, cb, errCb);
	}

	// export csv
	static export(filter,sort, cb, errCb) {
		const token = CookieService.get("token");
		const path = new URL(Constants.API_ROUTES.USER.EXPORT);
		
		const filterParam = Object.values(filter).reduce(
			(accum, filterElem, i) =>
				accum.concat(`${i === 0 ? "" : ","}${filterElem.column}:${filterElem.value}`),
			""
		);
		path.searchParams.append("filter", filterParam);

		const sortParam = sort.reduce(
			(accum, { column, ascending }, i) =>
				accum.concat(`${i === 0 ? "" : ","}${column}:${ascending ? "asc" : "desc"}`),
			""
		);
		path.searchParams.append("sort", sortParam);

		HttpService.httpFileCall("GET", path, undefined, token, cb, errCb);
	}

	// update users
	static updateUsers(token, cb, errCb) {
		const path = new URL(Constants.API_ROUTES.USER.UPDATE);
		HttpService.httpCall("POST", path, {}, token, cb, errCb);
	}

}

import React from "react";
import { Card, CardHeader, CardBody, Col } from "reactstrap";
import "./ErrorCard.scss";

export default class ErrorCard {
	static render(title, body, big) {
		return (
			<Col md={big ? "12" : "8"}>
				<Card className="errorCard text-white bg-danger">
					<CardHeader>{title}</CardHeader>
					<CardBody>{body}</CardBody>
				</Card>
			</Col>
		);
	}
}

import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import I18n from "../src/views/utils/I18n";
import CookieService from "../src/services/CookieService";

import "./App.scss";
import Login from "./views/Pages/Login/Login";
import Forgot from "./views/Pages/Email/Forgot/Forgot";
import Recover from "./views/Pages/Email/Recover";
import Activate from "./views/Pages/Email/Activate";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Constants = require("./views/constants/Constants");

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {
	constructor(props) {
		super(props);

		let lang = CookieService.get("language");
		if (!lang) {
			lang = Constants.DEFAULT_LANGUAGE;
			CookieService.set("language", lang);
		}
		I18n.changeLanguage(lang);
	}

	render() {
		return (
			<HashRouter>
				<React.Suspense fallback={loading()}>
					<Switch>
						<Route exact path={Constants.ROUTES.LOGIN} component={Login} />
						<Route exact path={Constants.ROUTES.ACCOUNT_ACTIVATION + "/:userId/:code"} component={Activate} />

						<Route
							exact
							path={Constants.ROUTES.PASSWORD_RECOVER + "/:userId"}
							name="Page 404"
							render={(props) => <Page404 {...props} />}
						/>
						<Route path={Constants.ROUTES.PASSWORD_RECOVER + "/:userId/:code"} component={Recover} />

						<Route exact path={Constants.ROUTES.FORGOT} component={Forgot} />
						<Route exact path={Constants.ROUTES.USERS} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.PROFILE} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.TRANSACTION_ID} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.TRANSACTION} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.CONVERSATION_ID} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.CONVERSATION} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.ADMINS} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.ADMIN_ID} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.ADMIN} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.FUNDS_AND_BONDS} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.FUND_ID} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.FUND} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.BOND_ID} render={(props) => <DefaultLayout {...props} />} />
						<Route exact path={Constants.ROUTES.BOND} render={(props) => <DefaultLayout {...props} />} />

						<Route render={(props) => <Page404 {...props} />} />
					</Switch>
				</React.Suspense>
			</HashRouter>
		);
	}
}

export default App;

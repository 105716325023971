import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import {
	Button,
	Card,
	CardGroup,
	Col,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	CardBody,
} from "reactstrap";

import "./Login.scss";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import { ToastContainer } from "react-toastify";
import ErrorToast from "../../utils/toasts/ErrorToast";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "../../utils/Spinner";
import I18n from "../../utils/I18n";
import AdminService from "../../../services/AdminService";

import CookieService from "../../../services/CookieService";
import { getToken } from "../../../firebaseInit";

const Constants = require("../../constants/Constants");

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			message: false,
			error: false,
			email: "",
			password: "",
		};
	}

	componentDidMount() {
		this.setState({ loading: false, message: false, error: false, email: "", password: "" });
	}

	// log in with the server and verify that the user is an admin
	onLogin = (event) => {
		event.preventDefault();

		let self = this;
		self.setState({ loading: true });
		AdminService.login(
			this.state.email,
			this.state.password,
			async function (userData, token) {
				// const user = userData.user;
				const session = userData.session
				console.log('session', session)
				CookieService.set("token", session.token);
				CookieService.set("refreshToken", session.refreshToken);
				const notifToken = await getToken();
				AdminService.saveNotifToken(
					session.token,
					notifToken,
					() => {console.log("Success saving notif token")},
					(err) => {console.log("Error saving notif token", err)}
				)
				self.setState({ loading: false });
			},
			function (err) {
				console.log(err);
				self.setState({ error: err, message: false, loading: false });
			}
		);
	};

	responseFacebook = (response) => {
	};

	responseGoogle = (response) => {
	};

	// sends an email with the recovery code
	toSendRecoveryEmailPage = () => {
		this.props.history.push(Constants.ROUTES.FORGOT);
	};

	// mail and password set
	isSendEnabled = () => {
		return this.state.email && this.state.password;
	};

	render() {
		if (CookieService.get("token")) {
			return <Redirect to={Constants.ROUTES.USERS} />;
		}

		const loading = this.state.loading;
		const disabled = !this.isSendEnabled();
		return (
			<div className={loading ? "app flex-row align-items-center Loading" : "app flex-row align-items-center"}>
				{Spinner.render(loading)}
				<div className="col-md-8 col-sm-4 loginImageContainer">
					<img alt="background" className="loginImage" src={"assets/img/login-register.jpg"}></img>
				</div>
				<div className="col-md-4 col-sm-8 col-xs-12  loginCardContainer">
					<CardGroup className="loginCardGroup">{this.renderLoginCard(disabled)}</CardGroup>
				</div>
			</div>
		);
	}

	renderErrorCard = () => {
		const error = this.state.error;
		return (
		error &&
		ErrorToast.notifyError(
			error.errorMessage || I18n.t("ERRORS.ERROR"),
			error.errorCode
		)
		);
	};

	renderLoginCard = (disabled) => {
		return (
			<Card className="p-4 loginCard">
				<img
					alt=""
					className={"logo"}
					src={"assets/img/logo.png"}
				></img>
				<CardBody className="loginCardBody">
					<Form onSubmit={this.onLogin} id="login-form" disabled={disabled}>
						<h1 style={{ textAlign: "center", paddingBottom: 20 }}>{I18n.t("LOGIN.TITLE")}</h1>
						<p className="text-muted">{I18n.t("LOGIN.LOGIN")}</p>
						{this.renderEmailInput()}
						{this.renderPassInput()}
						<Row className="loginButton">
							<Col xs="6" className="loginButton">{this.renderLoginButton(disabled)}</Col>
						</Row>
					</Form>
					<Row>
						<Col xs="12" m="12" className="text-center">
							<ToastContainer />
							<div>{this.renderErrorCard()}</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	};

	renderEmailInput = () => {
		return (
			<InputGroup className="mb-3">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="icon-user"></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input
					type="text"
					placeholder={I18n.t("FIELDS.EMAIL")}
					onChange={(event) => {
						this.setState({ email: event.target.value, error: false, message: false });
					}}
				/>
			</InputGroup>
		);
	};

	renderPassInput = () => {
		return (
			<InputGroup className="mb-4">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="icon-lock"></i>
					</InputGroupText>
				</InputGroupAddon>
				<Input
					type="password"
					placeholder={I18n.t("FIELDS.PASS")}
					autoComplete="on"
					onChange={(event) => {
						this.setState({ password: event.target.value, error: false, message: false });
					}}
				/>
			</InputGroup>
		);
	};

	renderGoogleButton = () => {
		return (
			<GoogleLogin
				className="GoolgeLogin"
				clientId={Constants.GOOGLE.CLIENT_ID}
				buttonText=""
				onSuccess={this.responseGoogle}
				onFailure={this.responseGoogle}
				render={(renderProps) => <div onClick={renderProps.onClick} className="fa fa-google"></div>}
			/>
		);
	};

	renderFbButton = () => {
		return (
			<FacebookLogin
				appId={Constants.FACEBOOK.APP_ID}
				fields="name,email,picture"
				callback={this.responseFacebook}
				render={(renderProps) => <div onClick={renderProps.onClick} className="fa fa-facebook"></div>}
			/>
		);
	};

	renderForgotButton = () => {
		return (
			<Button color="link" className="px-0" style={{ paddingTop: "0px" }} onClick={this.toSendRecoveryEmailPage}>
				{I18n.t("LOGIN.FORGOT_PASSWORD")}
			</Button>
		);
	};

	renderLoginButton = (disabled) => {
		return (
			<Button color="primary" className="px-4" type="submit" form="login-form" disabled={disabled}>
				{I18n.t("LOGIN.BUTTON")}
			</Button>
		);
	};
}

export default withRouter(Login);
